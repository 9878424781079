/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { makeHandle as e } from "../handleUtils.js";
import { ObservationHandle as s } from "./tracking/ObservationHandle.js";
class r {
  constructor() {
    this._observers = null, this.destroyed = !1;
  }
  observe(e) {
    if (this.destroyed || e.destroyed) return t;
    null == this._observers && (this._observers = []);
    const r = this._observers;
    let o = !1,
      i = !1;
    const n = r.length;
    for (let s = 0; s < n; ++s) {
      const t = r[s];
      if (t.destroyed) i = !0;else if (t === e) {
        o = !0;
        break;
      }
    }
    return o || (r.push(e), i && this._removeDestroyedObservers()), new s(r, e);
  }
  _removeDestroyedObservers() {
    const e = this._observers;
    if (!e || 0 === e.length) return;
    const s = e.length;
    let r = 0;
    for (let t = 0; t < s; ++t) {
      for (; t + r < s;) {
        if (!e[t + r].destroyed) break;
        ++r;
      }
      if (r > 0) {
        if (!(t + r < s)) break;
        e[t] = e[t + r];
      }
    }
    e.length = s - r;
  }
  destroy() {
    if (this.destroyed) return;
    this.destroyed = !0;
    const e = this._observers;
    if (null != e) {
      for (const s of e) s.onCommitted();
      this._observers = null;
    }
  }
}
const t = e();
export { r as ObservableBase };