/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { makeHandle as n } from "../handleUtils.js";
import { runTracked as t } from "./tracking.js";
import { SimpleTrackingTarget as r } from "./tracking/SimpleTrackingTarget.js";
let u = !1;
const l = [];
function o(l, o) {
  let e = new r(a),
    i = null,
    s = !1;
  function a() {
    if (!e || s) return;
    if (u) return void c(a);
    const n = i;
    e.clear(), u = !0, s = !0, i = t(e, l), s = !1, u = !1, o(i, n), f();
  }
  function m() {
    e && (e.destroy(), e = null, i = null);
  }
  return s = !0, i = t(e, l), s = !1, n(m);
}
function e(u, l) {
  let o = new r(i),
    e = null;
  function i() {
    l(e, f);
  }
  function c() {
    o && (o.destroy(), o = null), e = null;
  }
  function f() {
    return o ? (o.clear(), e = t(o, u), e) : null;
  }
  return f(), n(c);
}
function i(l, o) {
  let e = !1,
    i = !1;
  const s = !!o?.sync;
  let a = new r(() => {
    e || i || (i = !0, s ? m() : queueMicrotask(m));
  });
  function m() {
    i = !1, a && !e && (u ? c(m) : (a.clear(), u = !0, e = !0, t(a, l), e = !1, u = !1, f()));
  }
  function p() {
    a && (a.destroy(), a = null);
  }
  return e = !0, t(a, l), e = !1, n(p);
}
function c(n) {
  l.includes(n) || l.unshift(n);
}
function f() {
  for (; l.length;) l.pop()();
}
export { i as autorun, o as reaction, e as reactionDeferred };