/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../ArrayPool.js";
import { makeHandle as t } from "../handleUtils.js";
import { equals as r, equalsShallow as l } from "../lang.js";
import { ReentrantObjectPool as o } from "../ReentrantObjectPool.js";
import { schedule as i } from "../scheduling.js";
import { someSet as n } from "../SetUtils.js";
import { generateUID as s } from "../uid.js";
import { valueOf as c } from "./get.js";
import { Lifecycle as u } from "./interfaces.js";
import { reactionDeferred as a, reaction as d } from "./trackingUtils.js";
import { parse as f } from "./utils.js";
var h;
!function (e) {
  e[e.Untracked = 0] = "Untracked", e[e.Tracked = 1] = "Tracked";
}(h || (h = {}));
class m {
  constructor() {
    this.uid = s(), this.removed = !1, this.type = null, this.oldValue = null, this.callback = null, this.getValue = null, this.target = null, this.path = null, this.equals = null;
  }
  static acquireUntracked(e, t, l, o, i) {
    return this.pool.acquire(h.Untracked, e, t, l, o, i, r);
  }
  static acquireTracked(e, t, r, l) {
    return this.pool.acquire(h.Tracked, e, t, r, null, null, l);
  }
  notify(e, t) {
    this.type === h.Untracked ? this.callback.call(this.target, e, t, this.path, this.target) : this.callback.call(null, e, t, void 0, void 0);
  }
  acquire(e, t, r, l, o, i, n) {
    this.uid = s(), this.removed = !1, this.type = e, this.oldValue = t, this.callback = r, this.getValue = l, this.target = o, this.path = i, this.equals = n;
  }
  release() {
    this.target = this.path = this.oldValue = this.callback = this.getValue = null, this.uid = s(), this.removed = !0;
  }
}
m.pool = new o(m);
const p = new e(),
  v = new Set();
let k;
function _(e) {
  v.delete(e), v.add(e), k || (k = i(q));
}
function g(e) {
  if (e.removed) return;
  const t = e.oldValue,
    r = e.getValue();
  e.equals(t, r) || (e.oldValue = r, e.notify(r, t));
}
function j(e) {
  for (const t of v.values()) t.target === e && (t.removed = !0);
}
function q() {
  let e = 10;
  for (; k && e--;) {
    k = null;
    const e = V(),
      t = p.acquire();
    for (const r of e) {
      const e = r.uid;
      g(r), e === r.uid && r.removed && t.push(r);
    }
    for (const r of v) r.removed && (t.push(r), v.delete(r));
    for (const r of t) m.pool.release(r);
    p.release(t), p.release(e), y.forEach(e => e());
  }
}
function V() {
  const e = p.acquire();
  e.length = v.size;
  let t = 0;
  for (const r of v) e[t] = r, ++t;
  return v.clear(), e;
}
const y = new Set();
function U(e) {
  return y.add(e), t(() => y.delete(e));
}
function T(e, r, l) {
  let o = f(e, r, l, (e, r, l) => {
    let i,
      n,
      s = a(() => c(e, r), (t, s) => {
        e.__accessor__?.lifecycle === u.DESTROYED || i && i.uid !== n ? o.remove() : (i || (i = m.acquireUntracked(t, l, s, e, r), n = i.uid), _(i));
      });
    return t(() => {
      s.remove(), i && (i.uid !== n || i.removed || (i.removed = !0, _(i)), i = null), o = s = null;
    });
  });
  return o;
}
function E(e, t, l) {
  const o = f(e, t, l, (e, t, l) => {
    let i = !1;
    return d(() => c(e, t), (n, s) => {
      e.__accessor__.lifecycle !== u.DESTROYED ? i || (i = !0, r(s, n) || l.call(e, n, s, t, e), i = !1) : o.remove();
    });
  });
  return o;
}
function b(e, r, l, o = !1) {
  return e.__accessor__ && e.__accessor__.lifecycle !== u.DESTROYED ? o ? E(e, r, l) : T(e, r, l) : t();
}
function D(e, r, l) {
  let o,
    i,
    n = a(e, (e, t) => {
      o && o.uid !== i ? n.remove() : (o || (o = m.acquireTracked(e, r, t, l), i = o.uid), _(o));
    });
  return t(() => {
    n.remove(), o && (o.uid !== i || o.removed || (o.removed = !0, _(o)), o = null), n = null;
  });
}
function S(e, t, r) {
  let l = !1;
  return d(e, (e, o) => {
    l || (l = !0, r(o, e) || t(e, o), l = !1);
  });
}
function w(e, t, r = !1, o = l) {
  return r ? S(e, t, o) : D(e, t, o);
}
function O(e) {
  return n(v, t => t.oldValue === e);
}
export { U as afterDispatch, q as dispatch, O as isValueInUse, j as removeTarget, b as watch, w as watchTracked };