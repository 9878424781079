/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n(n, r) {
  for (const t of n.values()) if (r(t)) return !0;
  return !1;
}
function r(n, r) {
  if (!r) return n;
  for (const t of r) null != t && n.add(t);
  return n;
}
function t(n, r) {
  return null != r && n.add(r), n;
}
function u(n, t) {
  const u = new Set();
  return r(u, n), r(u, t), u;
}
function o(n, r) {
  const t = new Set();
  for (const u of r) n.has(u) && t.add(u);
  return t;
}
function e(n, r) {
  if (!n || !r) return !1;
  if (n === r) return !0;
  for (const t of n) if (!r.has(t)) return !1;
  return !0;
}
function f(n, r) {
  if (null == n && null == r) return !0;
  if (null == n || null == r || n.size !== r.size) return !1;
  for (const t of n) if (!r.has(t)) return !1;
  return !0;
}
function i(n, r) {
  const t = new Set(n);
  for (const u of r) t.delete(u);
  return t;
}
function c(n, r) {
  return i(u(n, r), o(n, r));
}
function l(n) {
  let r;
  for (r of n);
  return r;
}
export { r as addMany, t as addMaybe, i as difference, f as equals, o as intersection, e as isSubsetOf, l as last, n as someSet, c as symmetricDifference, u as union };