/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../../Logger.js";
import { ensureArrayTyped as r, ensureOneOfType as e, ensureRange as o, isLongFormType as n, ensureLongFormType as i, isOneOf as s, ensureOneOf as a, ensureNArrayTyped as c, ensureArray as f, ensureNArray as p, ensureType as u } from "../ensureType.js";
import { get as l } from "../get.js";
import { getPropertyMetadata as g, mergeProperty as y } from "../metadata.js";
import { set as d } from "../set.js";
function m(n = {}) {
  return (i, s) => {
    if (i === Function.prototype) throw new Error(`Inappropriate use of @property() on a static field: ${i.name}.${s}. Accessor does not support static properties.`);
    const a = Object.getOwnPropertyDescriptor(i, s),
      c = g(i, s);
    a && (a.get || a.set ? (c.get = a.get || c.get, c.set = a.set || c.set) : "value" in a && ("value" in n && t.getLogger("esri.core.accessorSupport.decorators.property").warn(`@property() will redefine the value of "${s}" on "${i.constructor.name}" already defined in the metadata`, n), c.value = n.value = a.value)), null != n.readOnly && (c.readOnly = n.readOnly);
    const f = n.aliasOf;
    if (f) {
      const t = "string" == typeof f ? f : f.source,
        r = "string" == typeof f ? null : !0 === f.overridable;
      let e;
      c.dependsOn = [t], c.get = function () {
        let r = l(this, t);
        if ("function" == typeof r) {
          e || (e = t.split(".").slice(0, -1).join("."));
          const o = l(this, e);
          o && (r = r.bind(o));
        }
        return r;
      }, c.readOnly || (c.set = r ? function (t) {
        this._override(s, t);
      } : function (r) {
        d(this, t, r);
      });
    }
    const p = n.type,
      u = n.types;
    if (!c.cast) {
      let t;
      p ? t = v(p) : u && (t = Array.isArray(u) ? r(e(u[0])) : e(u)), n.cast = h(n.cast, t);
    }
    y(c, n), n.range && (c.cast = o(c.cast, n.range));
  };
}
function j(t, r, e) {
  const o = g(t, e);
  o.json || (o.json = {});
  let n = o.json;
  return void 0 !== r && (n.origins || (n.origins = {}), n.origins[r] || (n.origins[r] = {}), n = n.origins[r]), n;
}
function v(t) {
  let r = 0,
    e = t;
  if (n(t)) return i(t);
  for (; Array.isArray(e) && 1 === e.length && "string" != typeof e[0] && "number" != typeof e[0];) e = e[0], r++;
  const o = e;
  if (s(o)) return 0 === r ? a(o) : c(a(o), r);
  if (1 === r) return f(o);
  if (r > 1) return p(o, r);
  const l = t;
  return l.from ? l.from : u(l);
}
function h(t, r) {
  if (t || r) return t ? r ? (e, o) => r(t(e, o), o) : t : r;
}
export { h as composeCasters, m as property, j as propertyJSONMeta };