/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { TrackingTarget as t } from "./TrackingTarget.js";
class s extends t {
  constructor(t) {
    super(new i(t)), this._observer && e.register(this, new WeakRef(this._observer), this);
  }
  destroy() {
    this._observer && e.unregister(this._observer), this.accessed?.clear(), this.clear(), this._observer?.destroy();
  }
}
class i {
  constructor(t) {
    this._notify = t, this._invalidCount = 0, this.destroyed = !1;
  }
  onInvalidated() {
    this._invalidCount++;
  }
  onCommitted() {
    if (this.destroyed) return;
    const t = this._invalidCount;
    if (1 === t) return this._invalidCount = 0, void this._notify();
    this._invalidCount = t > 0 ? t - 1 : 0;
  }
  destroy() {
    this.destroyed = !0, this._notify = r;
  }
}
const e = new FinalizationRegistry(t => {
  t.deref()?.destroy();
});
function r() {}
export { s as SimpleTrackingTarget };