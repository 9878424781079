/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { ObservableBase as t } from "./ObservableBase.js";
import { OriginId as i } from "./PropertyOrigin.js";
import { trackAccess as s, runTracked as e, trackExplicitDependencies as a, runUntracked as r } from "./tracking.js";
import { Flags as o } from "./tracking/Flags.js";
import { TrackingTarget as n } from "./tracking/TrackingTarget.js";
class g extends t {
  constructor(t, i) {
    super(), this.propertyName = t, this.metadata = i, this.trackingTarget = new n(this), this.flags = 0, this.flags = o.Dirty | (i.nonNullable ? o.NonNullable : 0) | (i.hasOwnProperty("value") ? o.HasDefaultValue : 0) | (void 0 === i.get ? o.DepTrackingInitialized : 0) | (void 0 === i.dependsOn ? o.AutoTracked : 0);
  }
  destroy() {
    this.flags & o.Dirty && this.onCommitted(), super.destroy(), this.trackingTarget.destroy();
  }
  getComputed(t) {
    t.mutable && s(this);
    const n = t.store,
      g = this.propertyName,
      h = this.flags,
      l = n.get(g);
    if (h & o.Computing) return l;
    if (~h & o.Dirty && n.has(g)) return l;
    this.flags |= o.Computing;
    const m = t.host;
    let d;
    h & o.AutoTracked ? d = e(this.trackingTarget, this.metadata.get, m) : (a(m, this), d = this.metadata.get.call(m)), this.flags |= o.DepTrackingInitialized, n.set(g, d, i.COMPUTED);
    const c = n.get(g);
    return c === l ? this.flags &= ~o.Dirty : r(this.commit, this), this.flags &= ~o.Computing, c;
  }
  notifyChange() {
    this.onInvalidated(), this.onCommitted();
  }
  invalidate() {
    this.onInvalidated();
  }
  commit() {
    this.flags &= ~o.Dirty, this.onCommitted();
  }
  onInvalidated() {
    ~this.flags & o.Overridden && (this.flags |= o.Dirty);
    const t = this._observers;
    if (t && t.length > 0) for (const i of t) i.onInvalidated();
  }
  onCommitted() {
    const t = this._observers;
    if (t && t.length > 0) {
      const i = t.slice();
      for (const t of i) t.onCommitted();
    }
  }
}
export { g as Property };